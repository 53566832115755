import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//libraries
import Moment from 'moment-timezone'
import { Card, CardHeader, CardContent, Typography } from '@mui/material'
import { DeviceHub, MoreVert, Lock } from '@mui/icons-material'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import EditIcon from '@mui/icons-material/Edit'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
// Remote Image Loader
import CustomCard from '../../../Common/Card/CustomCard'
import RemoteImageLoader from '../../../RemoteImageLoader'
import NoProjectImage from '../../../../images/NoProjectImage.svg?url'
//action creators
import { openModal } from '../../../../store/projects/actionCreator'
import { convertUTCtoLocal } from '../../../../helpers/dateHelper'
import { ROUNDEL_CLIENT_FILES } from '../../../../constants/projects'

const ProjectDetailsWidget = ({ classes = {}, isAuthorizedUser = false }) => {
  const dispatch = useDispatch()
  const {
    projectAdmins = '',
    projectData = {},
    isAdmin = false,
    isCoverImgLoading = false,
    loading = {},
  } = useSelector((state) => state.projects)
  const [isProjectEl, setIsProjectEl] = useState(null)
  const {
    project_release_date: projectReleaseDate = null,
    run_date: projectRunDate = null,
    due_date: projectDueDate = null,
    creation_date: projectCreationDate = null,
    confidential = false,
    project_name = '',
    cover_image = '',
    project_code = '',
    project_id = '',
    campaign = '',
    channel = '',
    project_type = '',
  } = projectData

  const { projectDetails: loadingProjectDetails = {} } = loading

  const openEditProject = () => {
    setIsProjectEl(null)
    dispatch(openModal('editProject'))
  }

  const showLoader = (enableClasses = true) => (
    <div className={enableClasses ? classes.loader : ''}>
      <CircularProgress />
    </div>
  )

  const renderCardContent = () =>
    loadingProjectDetails ? (
      showLoader()
    ) : (
      <div className={classes.cardContent}>
        {isCoverImgLoading && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              WebkitJustifyContent: 'center',
              justifyContent: 'center',
              height: '200px',
            }}
          >
            <CircularProgress />
          </div>
        )}
        {!isCoverImgLoading && cover_image && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              WebkitJustifyContent: 'center',
              justifyContent: 'center',
            }}
          >
            <RemoteImageLoader
              Src={cover_image || NoProjectImage}
              AltText="asset"
              ClassName={classes.media}
            />
          </div>
        )}
        {!isCoverImgLoading && !cover_image && (
          <div
            style={{
              display: 'flex',
              border: '1px dashed #8D8D8D',
              minHeight: '325px',
              alignItems: 'center',
              WebkitJustifyContent: 'center',
              justifyContent: 'center',
            }}
          >
            <RemoteImageLoader
              Src={NoProjectImage}
              AltText="No Cover Img"
              ClassName={classes.media}
            />
          </div>
        )}

        <div style={{ marginTop: 10 }}>
          {confidential && (
            <div>
              <Typography color="textSecondary" variant="caption">
                Confidential Until:{' '}
              </Typography>{' '}
              {projectReleaseDate
                ? Moment(
                    new Date(projectReleaseDate).toISOString().substring(0, 10),
                  )
                    .utc()
                    .local()
                    .format('MMM DD, YYYY h:mm:ss a')
                : 'Indefinite'}
            </div>
          )}
          {confidential && project_code && (
            <div>
              <Typography color="textSecondary" variant="caption">
                Code Name:{' '}
              </Typography>{' '}
              {project_code}
            </div>
          )}
          <div data-cy="projectId">
            <Typography color="textSecondary" variant="caption">
              {project_type === 'Roundel' ? `Salesforce ID: ` : `ID: `}
            </Typography>{' '}
            {project_id}
          </div>
          <div data-cy="projectCampaign">
            <Typography color="textSecondary" variant="caption">
              Campaign:{' '}
            </Typography>{' '}
            {campaign}
          </div>
          <div>
            <Typography color="textSecondary" variant="caption">
              Channel:{' '}
            </Typography>
            {channel}
          </div>
          <div data-cy="runDate">
            <Typography color="textSecondary" variant="caption">
              Run / Launch:{' '}
            </Typography>
            {projectRunDate &&
              convertUTCtoLocal(projectRunDate, 'MMM DD, YYYY h:mm a')}
          </div>
          <div data-cy="dueDate">
            <Typography color="textSecondary" variant="caption">
              Due:{' '}
            </Typography>
            {projectDueDate &&
              convertUTCtoLocal(projectDueDate, 'MMM DD, YYYY h:mm a')}
          </div>
          <div data-cy="createdDate">
            <Typography color="textSecondary" variant="caption">
              Created:{' '}
            </Typography>
            {projectCreationDate &&
              convertUTCtoLocal(projectCreationDate, 'MMM DD, YYYY h:mm a')}
          </div>
          <div>
            <Typography color="textSecondary" variant="caption">
              Admin:{' '}
            </Typography>
            {projectAdmins || ''}
          </div>
        </div>
      </div>
    )

  const renderCardHeaderActions = () =>
    isAdmin && isAuthorizedUser ? (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : null}
          aria-haspopup="true"
          onClick={(event) => {
            setIsProjectEl(event.currentTarget)
          }}
          size="large"
          data-cy="moreProjectDetails"
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={isProjectEl}
          open={Boolean(isProjectEl)}
          onClose={() => setIsProjectEl(null)}
        >
          <MenuItem
            onClick={() => {
              openEditProject()
            }}
            data-cy={'editProject'}
          >
            <EditIcon className={classes.editIcon} /> Edit Project Details
          </MenuItem>
        </Menu>
      </div>
    ) : (
      ''
    )

  const cardConfig = {
    cardHeader: {
      headerAvatar: (
        <Avatar aria-label="Header Avatar">
          {confidential ? <Lock /> : <DeviceHub />}
        </Avatar>
      ),
      headerActionContent: renderCardHeaderActions(),
      title: project_name,
      subheader: confidential
        ? 'Active Confidential Project'
        : 'Active Project',
      identifier: '',
    },
    displayFooter: false,
  }

  return (
    <CustomCard
      data-cy="projectCard"
      cardConfig={cardConfig}
      cardContent={renderCardContent()}
    />
  )
}

export default ProjectDetailsWidget
